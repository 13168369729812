var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('page_users_group_title'))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.openAddModel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('page_users_group_create_button'))+" ")],1)],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.groups,"number-of-pages":_vm.totalPageCount,"total-records":_vm.totalRecordCount},on:{"click:row":_vm.goToEdit},scopedSlots:_vm._u([{key:"item.postdate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.postdate))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.title))])]}},{key:"item.publisher",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.publisher))])]}},{key:"item.clicks",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.clicks))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'Inactive')?_c('span',{staticClass:"text-capitalize color-red"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status == 'Private')?_c('span',{staticClass:"text-capitalize color-red"},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status == 'Publish')?_c('span',{staticClass:"text-capitalize color-green"},[_vm._v(_vm._s(item.status))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.openEditModel(item)}}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("$edit")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary"}},[_c('v-icon',{on:{"click":function($event){_vm.deleteItem = item
                  _vm.deleteItemDlg = true}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1)],1),_c('addModel'),_c('editModel'),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"500"},model:{value:(_vm.deleteItemDlg),callback:function ($$v) {_vm.deleteItemDlg=$$v},expression:"deleteItemDlg"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t('confirmation')))]),_c('v-card-text',[_c('v-row',{staticClass:"text-h7 pa-3 pt-7"},[_vm._v(" "+_vm._s(_vm.$t('page_users_group_confirmation_message'))+" ")])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":_vm.formStatus,"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.formStatus,"color":"red","dark":""},on:{"click":function($event){_vm.deleteItemDlg = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }