<template>
  <div slot="content">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="auto">
              {{ $t('page_users_group_title') }}
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto">
              <v-btn
                @click="openAddModel()"
                color="primary"
                text
                class="text-capitalize font-weight-regular"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t('page_users_group_create_button') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0">
          <Table
            :headers="headers"
            :items="groups"
            :number-of-pages="totalPageCount"
            :total-records="totalRecordCount"
            @click:row="goToEdit"
          >
            <template v-slot:item.postdate="{ item }">
              <span class="text-capitalize">{{ item.postdate }}</span>
            </template>

            <template v-slot:item.title="{ item }">
              <span class="text-capitalize">{{ item.title }}</span>
            </template>

            <template v-slot:item.publisher="{ item }">
              <span class="text-capitalize">{{ item.publisher }}</span>
            </template>

            <template v-slot:item.clicks="{ item }">
              <span class="text-capitalize">{{ item.clicks }}</span>
            </template>

            <template v-slot:item.status="{ item }">
              <span
                v-if="item.status == 'Inactive'"
                class="text-capitalize color-red"
                >{{ item.status }}</span
              >
              <span
                v-if="item.status == 'Private'"
                class="text-capitalize color-red"
                >{{ item.status }}</span
              >
              <span
                v-if="item.status == 'Publish'"
                class="text-capitalize color-green"
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn @click="openEditModel(item)" icon color="secondary">
                <v-icon size="12">$edit</v-icon>
              </v-btn>
              <v-btn icon color="secondary">
                <v-icon
                  @click="
                    deleteItem = item
                    deleteItemDlg = true
                  "
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>

    <addModel></addModel>
    <editModel></editModel>
    <!-- Delete Model -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="deleteItemDlg"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{ $t('confirmation') }}</v-toolbar>
        <v-card-text>
          <v-row class="text-h7 pa-3 pt-7">
            {{ $t('page_users_group_confirmation_message') }}
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="formStatus"
            color="primary"
            class="mr-3"
            @click="deleteItemConfirm"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            :disabled="formStatus"
            color="red"
            dark
            @click="deleteItemDlg = false"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import editModel from '@/views/admin/User/Group/editModel'
import addModel from '@/views/admin/User/Group/addModel'

export default {
  name: 'index',
  components: { Table, editModel, addModel },
  created() {
    let this_this = this
    this.getDataFromApi()
    this.$root.$on('onSaveGroup', function() {
      this_this.getDataFromApi()
    })
  },
  computed: {
    groups() {
      return this.$store.getters.groups
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id' },
        {
          text: this.$t('page_users_group_table_header_name'),
          align: 'center',
          value: 'name'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      totalPageCount: 1,
      totalRecordCount: 50,
      loading: false,
      deleteItemDlg: false,
      deleteItem: {},
      formStatus: false
    }
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      let data = {}
      data.sort = 'id'
      data.order = 'desc'
      data.is_draft = 0
      await this.$store.dispatch('GROUP_GET_ALL', data)
      this.loading = false
    },
    openAddModel() {
      this.$root.$emit('openGroupAddModel', true)
    },
    openEditModel(item) {
      this.$root.$emit('openGroupEditModel', item)
    },
    deleteItemConfirm() {
      this.deleteItemDlg = false
      this.formStatus = true

      this.$store.dispatch('GROUP_DELETE', this.deleteItem).then(() => {
        this.groups.splice(this.groups.indexOf(this.deleteItem), 1)
        this.formStatus = false
      })
    },
    goToEdit(item) {
      this.openEditModel(item)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
