<template>
  <v-dialog
    ref="localModelOpened"
    v-model="localModelOpened"
    persistent
    width="460px"
  >
    <validation-observer ref="observer">
      <v-form @submit.prevent="submit" ref="observer_form">
        <v-card class="pb-10">
          <div class="d-flex justify-space-between">
            <v-card-title>Create New Group </v-card-title>
            <v-btn color="red" text @click="closeModel()">x</v-btn>
          </div>
          <v-divider></v-divider>
          <table class="createposttable">
            <tbody>
              <tr v-for="field in fields" :key="field.name">
                <td class="tdclass">
                  <v-label>{{ field.label }}</v-label>
                </td>
                <td v-if="field.type == 'text'">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.rules"
                  >
                    <v-text-field
                      :placeholder="field.placeholder"
                      dense
                      class="input-class widthfull"
                      v-model="field.value"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </td>

                <td v-if="field.type == 'date'">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.rules"
                  >
                    <v-dialog
                      ref="post_date_dialog"
                      v-model="field.model"
                      :return-value.sync="field.value"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :placeholder="field.placeholder"
                          dense
                          class="input-class widthfull"
                          v-model="field.value"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="field.value" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="field.model = false"
                        >
                          {{ $t('cancel') }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.post_date_dialog[0].save(field.value)"
                        >
                          {{ $t('ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </validation-provider>
                </td>

                <td v-if="field.type == 'toggle'">
                  <validation-provider :name="field.name" :rules="field.rules">
                    <toggle-button
                      v-model="field.value"
                      :color="colorObj"
                      style="float: left"
                      :width="42"
                      :value="field.value"
                      :height="19"
                    /><span
                      :class="[
                        field.value
                          ? 'tgl-btn-class-green'
                          : 'tgl-btn-class-red'
                      ]"
                      >{{ field.value ? 'Publish' : 'Private' }}</span
                    >
                  </validation-provider>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btn-center">
            <v-btn
              type="submit"
              :disabled="formStatus"
              :loading="formStatus"
              class="btn-class-submit"
              >{{ $t('submit') }}</v-btn
            >
          </div>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import toggleButton from 'vue-js-toggle-button'
Vue.use(toggleButton)

export default {
  data() {
    return {
      colorObj: { checked: '#046C21', unchecked: '#D14141' },
      localModelOpened: false,

      fields: [
        {
          label: 'Name',
          name: 'name',
          type: 'text',
          placeholder: 'Enter Group Name',
          value: '',
          model: false,
          rules: 'required'
        }
      ],
      formStatus: false
    }
  },
  created() {
    let this_this = this
    this.$root.$on('openGroupAddModel', function() {
      this_this.localModelOpened = true
    })
  },
  methods: {
    closeModel() {
      this.localModelOpened = false
    },
    async submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        let data = {}

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, 'additional_field')) {
            data[field.additional_field.name] = field.additional_field.value
          }
          data[field.name] = field.value
        })

        this.$store
          .dispatch('GROUP_ADD_NEW', data)
          .then(result => {
            this.formStatus = false
            this.localModelOpened = false
            this.$root.$emit('onSaveGroup', true)
            if (result.status === 200) {
              this.$refs.observer_form.reset()
              this.$refs.observer.reset()
            }
          })
          .catch(error => {
            this.$refs.observer.setErrors(error.data.error.errors)
            this.formStatus = false
          })
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
