import { render, staticRenderFns } from "./addModel.vue?vue&type=template&id=8533d6a2&scoped=true&"
import script from "./addModel.vue?vue&type=script&lang=js&"
export * from "./addModel.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=8533d6a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8533d6a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardTitle,VDatePicker,VDialog,VDivider,VForm,VLabel,VSpacer,VTextField})
